<template>
	<div :class="cssClasses">
		<div class="myloading-text">{{loadingLabel}}</div>
		<div class="myloading-content"></div>
	</div>
</template>

<script>
export default {
	props : {
		loadingLabel : {
			type : String,
			default : "LOADING"
		} ,
		size : {
			type : String,
			default : ''
		}
	} ,
	computed : {
		cssClasses() {
			return [
				'myloading-wrapper' ,
				{
					'myloading-wrapper-sm' : this.size == 'small'
				}
			]
		}
	}
}
</script>

<style lang="less" scoped>
	.myloading-wrapper {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
	}
	.myloading-text {
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		color: @primary-color;
		width: 100px;
		line-height: 30px;
		margin: -15px 0 0 -50px;
		text-align: center;
		font-family: sans-serif;
		font-size: 20px;

	}

	.myloading-content {
		display: block;
		position: relative;
		left: 50%;
		top: 50%;
		width: 170px;
		height: 170px;
		margin: -85px 0 0 -85px;
		border: 3px solid @primary-color;
	}

	.myloading-content {
		border: 3px solid transparent;
		border-top-color: @primary-color;
		border-bottom-color: @primary-color;
		border-radius: 50%;
		-webkit-animation: pageloader 2s linear infinite;
		-moz-animation: pageloader 2s linear infinite;
		-o-animation: pageloader 2s linear infinite;
		animation: pageloader 2s linear infinite;
	}

	@keyframes pageloader {
		0% {
			-webkit-transform: rotate(0deg);
			-ms-transform: rotate(0deg);
			transform: rotate(0deg);
		}

		100% {
			-webkit-transform: rotate(360deg);
			-ms-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}

	.myloading-wrapper-sm {
		.myloading-content {
			width: 120px;
			height: 120px;
			margin: -60px 0 0 -60px;
		}
		.myloading-text {
			width: 100px;
			line-height: 30px;
			margin: -15px 0 0 -50px;
			font-size: 14px;
		}
	}
</style>
