<i18n locale="th" lang="yaml" >
line.processing : "กำลังตรวจสอบกับข้อมูลในระบบ"

line.login.error : "การเข้าสู่ระบบด้วย LINE มีปัญหา"
line.bind.error : "การผูกบัญชีด้วย LINE มีปัญหา"

line.login.binding.title : "LINE ของคุณไม่มีในระบบ"
line.login.binding.suggestion : "ถ้าคุณต้องการผูกข้อมูล LINE กรุณาระบุชื่อผู้ใช้และรหัสผ่านของคุณ"
line.login.binding.submit : "ผูกผู้ใช้งาน"

line_bind.success : "ทำการผูกบัญชีกับ LINE {lineName} เรียบร้อย"
line_bind.confirm.title: "ผูกบัญชีกับ LINE Account?"
line_bind.confirm.content: "คุณ {name} ต้องการผูกบัญชีผู้ใช้งานของคุณกับ LINE Account ? (เพื่อช่วยในการสื่อสารมากขึ้น)"
username : "ชื่อผู้ใช้งาน"
username.placeholder : "ระบุชื่อผู้ใช้งาน"
password : "รหัสผ่าน"
password.placeholder : "รหัสผ่าน"
password.err.pattern : "@:common.password.pattern"
</i18n>

<template>
	<div>
		<ErrorPage v-if="mode == 'error'"
			:code-description="callbackError.description">
			<span slot="code">
				<span class="text-error text-bold line-error">{{callbackError.error}}</span>
			</span>
		</ErrorPage>
		<div v-else class="line-box">
			<template v-if="loading">
				<MyLoadingSpin />
				<div class="loading-description">
					{{$t('line.processing')}}
				</div>
			</template>
			<div v-else class="content">
				<div class="line-title">
					{{$t('line.login.binding.title')}}
				</div>
				<LineProfileCard
					class="line-info"
					:line-profile="lineProfile"/>
				<div class="line-suggestion">
					{{$t('line.login.binding.suggestion')}}
				</div>

				<a-form
					layout="vertical"
					class="line-login-form"
					:form="formObj"
					@submit.prevent="handleSubmit">

					<a-form-item :label="$t('username')">
						<a-input
							v-decorator="[
								'username' ,
								{ rules: [
									{ required: true,whitespace: true, message: $tt('validate.required','username') },
								] },
							]"
							size="large"
							type="text"
							:placeholder="$t('username.placeholder')">
							<a-icon
								slot="prefix"
								type="user"/>
						</a-input>
					</a-form-item>

					<a-form-item :label="$t('password')">
						<a-input
							v-decorator="[
								'password' ,
								{ rules: [
									{ required: true, message: $tt('validate.required','password') },
									{ pattern: /^(?=.*[a-zA-Z#$^+=!*()@%&])(?=.*\d).{6,40}$/ ,message: $t('password.err.pattern') },
								] },
							]"
							size="large"
							type="password"
							:placeholder="$t('password.placeholder')">
							<ion-icon slot="prefix" name="key-outline" class="anticon" />
						</a-input>
					</a-form-item>
					<div class="form-submit-row">
						<a-button
							icon="left"
							size="large"
							class="back-btn"
							:disabled="formLoading"
							@click="goBack">
							{{$t('common.back')}}
						</a-button>
						<a-button
							type="primary"
							class="submit-btn"
							style="width : 175px;"
							:loading="formLoading"
							size="large"
							html-type="submit">
							{{$t('line.login.binding.submit')}}
						</a-button>
					</div>
				</a-form>
			</div>
		</div>
	</div>
</template>

<script>
import LoginInfoMixin from "@mixins/LoginInfoMixin.vue"
import ErrorPage from "@components/ErrorPage.vue"
import axios from "axios"
import { mapActions } from 'vuex'
import ApiError from '@utils/errors/ApiError'
import MyLoadingSpin from "@components/common/MyLoadingSpin.vue"
import HasAntdFormMixin from '@mixins/HasAntdFormMixin.vue'
import LineProfileCard from "@components/user/LineProfileCard.vue"
export default {
	components : {
		ErrorPage,MyLoadingSpin,LineProfileCard
	} ,
	mixins : [LoginInfoMixin,HasAntdFormMixin] ,
	data() {
		return {
			loading : false,
			mode : 'login',
			callback : {
				code : '',
				state : '',
			} ,
			callbackError : {
				error : '',
				description : '',
			} ,
			lineProfile : {
			} ,
		}
	} ,
	mounted() {
		this.processUrl()
	},
	methods : {
		...mapActions('auth', ['loginWithLine','loginAndLine','updateCurrentLineAccount','updateCurrentUser']),

		processUrl() {
			window.history.replaceState('','','/')
			if (!this.$route.query)
				return
			this.loading = true
			this.mode = 'error'
			this.callbackError.error = ''
			this.callbackError.description = ''
			this.callback.code = ''
			this.callback.state = ''
			if (this.$notEmpty(this.$route.query.state)) {
				this.callback.state = this.$route.query.state
				if (this.callback.state.startsWith("login::"))
					this.mode = 'login'
				else if (this.callback.state.startsWith("bind::"))
					this.mode = 'bind'
			}

			if (this.$notEmpty(this.$route.query.error)) {
				// It's Error
				this.mode = 'error'
				this.callbackError.error = this.$route.query.error
				this.callbackError.description = this.$route.query.error_description
			} else if (this.$notEmpty(this.$route.query.code)) {
				this.callback.code = this.$route.query.code
			} else {
				this.mode = 'error'
			}

			if (this.mode == 'login') {
				this.loginWithLine(this.callback).then((result) => {
					if (result.success === true) {
						this.$router.push(this.$route.query.redirect || { name: 'dashboard' })
						return
					}
					this.lineProfile = result.line_profile
					this.loading = false
				}).catch((error) => {
					this.mode = 'error'
					this.callbackError.error = this.$t('line.login.error')
					this.callbackError.description = ApiError.getDisplayErrorMessage(this,error)
					this.loading = false
				})
			} else if (this.mode == 'bind') {
				axios.post("/api/users/bind-line-account",{code : this.callback.code,state :this.callback.state}).then((response) => {
					const user = response.data.data.user
					const lineAccount = response.data.data.line_account

					this.updateCurrentLineAccount(lineAccount)
					this.updateCurrentUser(user)
					this.$router.push("/")
					this.$message.success(this.$t('line_bind.success',{lineName : lineAccount.display_name}))
				}).catch((error) => {
					this.mode = 'error'
					this.callbackError.error = this.$t('line.login.error')
					this.callbackError.description = ApiError.getDisplayErrorMessage(this,error)
					this.loading = false
				})
			} else {
				this.loading = false
			}
		} ,
		goBack() {
			this.$router.push("/")
		} ,
		handleSubmit() {
			this.formObj.validateFields((err, values) => {
				if (err) {
					this.displayValidateErrorMessage()
					return
				}
				const formData = {
					username: values.username,
					password: values.password,
					line_profile : this.lineProfile
				}
				this.$confirm({
					title : this.$t('line_bind.confirm.title') ,
					content : this.$t('line_bind.confirm.content',{ "name": values.username}) ,
					okText : this.$t('common.confirm') ,
					maskClosable : true,
					onOk : () => {
						this.formLoading = true
						this.loginAndLine(formData).then(() => {
							this.$router.push(this.$route.query.redirect || { name: 'dashboard' })
						}).catch((error) => {
							this.formSubmitErrors(error)
						}).finally(() => {
							this.formLoading = false
						})
					} ,
				})
			})
		} ,
		bindAccount() {

		}
	} ,

}
</script>

<style lang="less" scoped>
.line-error {
	font-size : 28px;
}
.line-box {
	margin: 0 auto;
	max-width: 650px;
	min-height : 500px;
	border-radius: 16px;
	background-color: @white;
	box-shadow: @shadow-2;
	.content {
		padding: 32px 32px 24px;
		text-align: left;

		.mobile & {
			padding : 24px 16px 16px;
		}
	}
	.loading-description {
		font-family : @font-family-title;
		font-size : 16px;
		color : @primary-color;
		padding-top : 64px;
		text-align : center;
	}
}
.line-title {
	text-align : center;
	font-family: @font-family-title;
	font-size : 1.5em;
	color : @primary-color;
}
.line-suggestion {
	text-align : center;
	color : @text-muted;
}
.line-info {
	margin : 16px auto;
}
.line-login-form {
	margin : 32px auto 24px;
	max-width : 400px;
}
.form-submit-row {
	display : flex;
	.submit-btn {
		margin-left : 8px;
		flex-grow: 1;
	}
}
</style>
